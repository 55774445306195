<template>
  <div class="not-found">
    <h1 class="not-found__title">Ruta no encontrada</h1>
    <h3 class="not-found__title">
      Lo sentimos, esta página no existe. Por favor, compruebe la URL o
      retroceda una página.
    </h3>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  min-height: 94.7vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.4);
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/404_not_found.gif");
  background-size: 50rem;
  background-position: center;
  &__title {
    font-family: Georgia, serif;
    color: #1d2124;
    margin-top: 2em;
    font-weight: bold;
  }
}
</style>